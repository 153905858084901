import React, {useEffect, useRef, useState} from "react";
import "./EnterDate.scss";
import DatePicker from "../DatePicker/DatePicker";
import {CalenderIcon, RightMark} from "../../../Icons";
import {Filter, FilterSearch} from "../../../types/types";
import {useDispatch, useSelector} from "react-redux";
import {ReducerStateTypes} from "../../../types/redux/ReducerStateTypes";
import {setActiveFilters} from "../../../actions";
import DurationUtil from "../../../utils/DurationUtil";
import UrlUtil from "../../../utils/UrlUtil";
import {useSearchParams} from "react-router-dom";
import FilterUtil from "../../../utils/FilterUtil";
import selectArrowImage from "../../../assets/img/select-arrow.svg";

const EnterDate: React.FC<{filters: Filter[]}> = ({
                                 filters
                             }) => {
    const [isopenEnterDateDropdown, setIsopenEnterDateDropdown] = useState(false);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const selectedFilters: FilterSearch[] = useSelector((state: ReducerStateTypes) => state.filter.selectedFilters);
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const onOutsideClick = () => {
        setIsopenEnterDateDropdown(false);
    };
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target as Node)
            ) {
                onOutsideClick();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onOutsideClick]);

    // tab
    const [activeTab, setActiveTab] = useState("datum");

    //datum flexibel
    const [dFlexibelDropdown, setDFlexibelDropdown] = useState(false);
    const selectDFlexibelRef = useRef<HTMLDivElement | null>(null);
    const onOutClickDFlexibel = () => {
        setDFlexibelDropdown(false);
    };
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                selectDFlexibelRef.current &&
                !selectDFlexibelRef.current.contains(event.target as Node)
            ) {
                onOutClickDFlexibel();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onOutClickDFlexibel]);

    //Datum Reisduur
    const [dReisduurDropdown, setDReisduurDropdown] = useState(false);
    const selectDReisduurRef = useRef<HTMLDivElement | null>(null);
    const onOutClickDReisduur = () => {
        setDReisduurDropdown(false);
    };
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                selectDReisduurRef.current &&
                !selectDReisduurRef.current.contains(event.target as Node)
            ) {
                onOutClickDReisduur();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onOutClickDReisduur]);

    //Maand Reisduur
    const [mReisduurDropdown, setMReisduurDropdown] = useState(false);
    const selectMReisduurRef = useRef<HTMLDivElement | null>(null);
    const onOutClickMReisduur = () => {
        setMReisduurDropdown(false);
    };
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                selectMReisduurRef.current &&
                !selectMReisduurRef.current.contains(event.target as Node)
            ) {
                onOutClickMReisduur();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onOutClickMReisduur]);
    //Select Date
    const [dateDropdown, setDateDropdown] = useState(false);
    const dateRef = useRef<HTMLDivElement | null>(null);
    const onOutClickDate = () => {
        setDateDropdown(false);
    };
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dateRef.current && !dateRef.current.contains(event.target as Node)) {
                onOutClickDate();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onOutClickDate]);

    const handleDuration = (duration: string | null) => {
        if (duration === null) {
            setSearchParams(params => {
                params.set("filter", UrlUtil.parseFiltersToUrlParams(selectedFilters.filter(f => f.filterType !== "duration")));
                return params;
            });
            dispatch(setActiveFilters(selectedFilters.filter(f => f.filterType !== "duration")));
            return;
        }
        let urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
        let activeFilters: FilterSearch[] = [...selectedFilters, ...urlFilters];
        const index: number = activeFilters.findIndex(filter => filter.filterType === "duration");
        const filter: FilterSearch = {
            filterCategory: "vacation",
            filterType: "duration",
            value: duration,
            displayValue: DurationUtil.renderDurationDisplayValue(duration)
        };
        if (index > -1) {
            activeFilters = activeFilters.filter(f => f.filterType !== "duration");
        }
        activeFilters.push(filter);

        setSearchParams(params => {
            params.set("filter", UrlUtil.parseFiltersToUrlParams(FilterUtil.removeDuplicates([...activeFilters])));
            return params;
        });

        dispatch(setActiveFilters([...activeFilters]));
    };

    const handleDepartureMonth = (e: React.ChangeEvent<HTMLInputElement>, item: {displayValue: string, value: string, count: number}): void => {
        const index: number = selectedFilters.findIndex(f => f.value === item.value);
        let activeFilters: FilterSearch[] = [...selectedFilters.filter(f => ((f.filterType !== "departure" && f.filterSubCategory !== "date") && (f.filterType !== "flexible" && f.filterSubCategory !== "departure")))];
        let urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));

        if (e.target.checked && index === -1) {
            activeFilters = [...activeFilters, {
                filterCategory: "vacation",
                filterSubCategory: "flexible",
                filterType: "departure",
                value: item.value,
                displayValue: item.displayValue
            }];
        } else {
            activeFilters.splice(index, 1);
        }
        setSearchParams(params => {
            params.set("filter", UrlUtil.parseFiltersToUrlParams(
                FilterUtil.removeDuplicates(
                    [
                        ...activeFilters,
                        ...urlFilters.filter((f) => {
                            return (f.filterType !== "departure" && (f.filterSubCategory !== "date"))
                        })
                    ]
                )
            ));
            return params;
        });
        dispatch(setActiveFilters([...activeFilters]));
    }


    let duration: FilterSearch | undefined = selectedFilters.find(f => f.filterType === "duration");
    let departure: FilterSearch | undefined = selectedFilters.find(f => f.filterType === "departure");

    const renderDateDurationValue = () => {

        if (departure === undefined) {
            const urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
            departure = urlFilters.find(f => f.filterType === "departure");
        }

        if (duration === undefined) {
            const urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
            duration = urlFilters.find(f => f.filterType === "duration");
        }

        if (departure && duration) {
            return (
                <div>
                    <h5 style={{margin: 0}}>Vertrekken op: {departure.displayValue}</h5>
                    <h5 style={{margin: 0}}>Reisduur: {duration.displayValue}</h5>
                </div>
            );
        }
        if (departure && !duration) {
            return `Vertrekken op: ${departure.displayValue}`
        }

        if (!departure && duration) {
            return `Reisduur: ${duration.displayValue}`
        }

        return "Selecteer reisduur"
    }

    let flexibility: any = [
        ...selectedFilters,
        ...UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"))
    ].find(f => f.filterType === "flexible" && f.filterSubCategory === "departure");

    const handleFlexibility = (flexibility: number) => {
        const filter: FilterSearch = {
            filterCategory: "vacation",
            filterType: "flexible",
            filterSubCategory: "departure",
            value: flexibility.toString(),
            displayValue: flexibility === 0 ? "Alleen deze datum" : `${flexibility} dag(en) eerder of later`
        };

        const urlFilters: FilterSearch[] = UrlUtil.parseUrlParamsToFilters(searchParams.get("filter"));
        const activeFilters: FilterSearch[] = FilterUtil.removeDuplicates([...selectedFilters, ...urlFilters]);
        const index: number = activeFilters.findIndex(f => f.filterType === "flexible" && f.filterSubCategory === "departure");

        if (index === -1) {
            activeFilters.push(filter);
        } else {
            activeFilters[index] = filter;
        }

        setSearchParams(params => {
            params.set("filter", UrlUtil.parseFiltersToUrlParams(activeFilters));
            return params;
        });

        dispatch(setActiveFilters([...activeFilters]));
    }

    const renderMonths = (filters: Filter[]) => {
        const months = filters.find(f => f.filterType === "departure_flexible");
        if (months && months.values) {
            return months.values.map((item, i) => (
                <button key={i} className="select-btn">
                    <input
                        onChange={(e) => handleDepartureMonth(e, item)}
                        type="checkbox"
                        name=""
                        id={item.value}
                        checked={selectedFilters.findIndex(f => f.value === item.value) > -1}
                    />{" "}
                    <label htmlFor={item.value}>{item.displayValue} ({item.count})</label>
                </button>
            ));
        } else {
            return <b><i>Geen maanden beschikbaar</i></b>;
        }
    }

    return (
        <div id="enter-date" ref={wrapperRef}>
            <h5 onClick={() => setIsopenEnterDateDropdown(!isopenEnterDateDropdown)}>
                {renderDateDurationValue()}
            </h5>

            {isopenEnterDateDropdown && (
                <div data-testid="date-duration-popup">
                    <div className="enter-date-contents-wrapper">
                        <div>
                            <div className="tabs-contents">
                                <button
                                    className={`${activeTab === "datum" && "active-tab"}`}
                                    onClick={() => setActiveTab("datum")}
                                >
                                    Datum
                                </button>
                                <button
                                    className={`${activeTab === "maand" && "active-tab"}`}
                                    onClick={() => setActiveTab("maand")}
                                >
                                    Maand
                                </button>
                            </div>

                            {activeTab === "datum" ? (
                                <div>
                                    <div className="select-btn-wrapper">
                                        <div>
                                            <h5>Vertrekdatum</h5>
                                            <button
                                                className="select-btn"
                                                onClick={() => setDateDropdown(true)}
                                            >
                                                {" "}
                                                {departure
                                                    ? departure.displayValue
                                                    : " Selecteer Vertrekdatum"}
                                                <CalenderIcon/>
                                                {dateDropdown && (
                                                    <div className="date-picker-wrapper " ref={dateRef}>
                                                        <DatePicker
                                                            setDateDropdown={setDateDropdown}
                                                        />
                                                    </div>
                                                )}
                                            </button>
                                        </div>
                                        <div>
                                            <h5>Flexibel</h5>

                                            <button
                                                className="select-btn"
                                                onClick={() => setDFlexibelDropdown(!dFlexibelDropdown)}
                                            >
                                                {flexibility
                                                    ? `${flexibility.displayValue}`
                                                    : 'Alleen deze datum'}

                                                <img src={selectArrowImage} alt=""/>
                                                {dFlexibelDropdown && (
                                                    <div
                                                        className="flexibel select-content"
                                                        ref={selectDFlexibelRef}
                                                    >
                                                        {[
                                                            {displayValue: 'Alleen deze datum', value: 0},
                                                            {displayValue: '1 dag eerder of later', value: 1},
                                                            {displayValue: '2 dagen eerder of later', value: 2},
                                                            {displayValue: '3 dagen eerder of later', value: 3},
                                                            {displayValue: '4 dagen eerder of later', value: 4},
                                                            {displayValue: '5 dagen eerder of later', value: 5}
                                                        ].map((it, i) => (
                                                            <div
                                                                key={i}
                                                                className="item"
                                                                onClick={() => {
                                                                    handleFlexibility(it.value);
                                                                    setDFlexibelDropdown(false);
                                                                }}
                                                            >
                                                                {it.displayValue}
                                                                {flexibility === it && (
                                                                    <RightMark/>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </button>
                                        </div>
                                        <div>
                                            <h5>Reisduur</h5>
                                            <button
                                                className="select-btn"
                                                onClick={() => setDReisduurDropdown(!dReisduurDropdown)}
                                            >
                                                {duration?.displayValue
                                                    ? `${duration.displayValue}`
                                                    : "Geen voorkeur"}

                                                <img src={selectArrowImage} alt=""/>
                                                {dReisduurDropdown && (
                                                    <div
                                                        className="select-content"
                                                        ref={selectDReisduurRef}
                                                    >
                                                        <h3 className="m-0 p-0 d-flex align-items-start">Reisduur</h3>
                                                        <div
                                                            className="item"
                                                            onClick={() => {
                                                                handleDuration(null);
                                                                setDReisduurDropdown(false);
                                                            }}>Geen voorkeur
                                                        </div>
                                                        <hr/>

                                                        {["7 Dagen", "14 Dagen", "21 Dagen"].map((it, i) => (
                                                            <div
                                                                key={i}
                                                                className="item"
                                                                onClick={() => {
                                                                    handleDuration(it)
                                                                    setDReisduurDropdown(false);
                                                                }}
                                                            >
                                                                {DurationUtil.renderDurationDisplayValue(it)}
                                                                {duration?.displayValue === it && (
                                                                    <RightMark/>
                                                                )}
                                                            </div>
                                                        ))}

                                                        <hr/>

                                                        {[
                                                            "1-3 Dagen",
                                                            "3-5 Dagen",
                                                            "4-6 Dagen",
                                                            "6-8 Dagen",
                                                            "7-9 Dagen",
                                                            "10-12 Dagen",
                                                            "13-15 Dagen",
                                                            "16-18 Dagen",
                                                            "19-21 Dagen",
                                                            "22-24 Dagen",
                                                            "25-27 Dagen",
                                                            "28-30 Dagen",
                                                        ].map((it, i) => (
                                                            <div
                                                                key={i}
                                                                className="item"
                                                                onClick={() => {
                                                                    handleDuration(it);
                                                                    setDReisduurDropdown(false);
                                                                }}
                                                            >
                                                                {it}
                                                                {duration?.displayValue === it && (
                                                                    <RightMark/>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </button>
                                        </div>
                                    </div>

                                    <div className="bottom-btn-group mt-5">
                                        {
                                            selectedFilters.findIndex(f => f.filterType === "departure" || f.filterType === "duration" || f.filterType === "flexible") > -1 &&
                                            <button
                                                className="link-btn"
                                                onClick={() => {
                                                    const newFilters = selectedFilters.filter(f => f.filterType !== "departure" && f.filterType !== "duration" && f.filterType !== "flexible");
                                                    setSearchParams(params => {
                                                        params.set("filter", UrlUtil.parseFiltersToUrlParams(FilterUtil.removeDuplicates(newFilters)));
                                                        return params;
                                                    });
                                                    dispatch(setActiveFilters([...newFilters]));
                                                }}>Verwijder keuze</button>
                                        }
                                        <button
                                            className="opslaan-btn"
                                            onClick={() =>
                                                setIsopenEnterDateDropdown(!isopenEnterDateDropdown)
                                            }
                                        >
                                            Opslaan
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="maand-wrapper">
                                    <div className="select-btn-wrapper">
                                        <div>
                                            <h5 className="p-0">Reisduur</h5>
                                            <button
                                                className="select-btn"
                                                onClick={() => setMReisduurDropdown(!mReisduurDropdown)}
                                            >
                                                {duration?.displayValue
                                                    ? `${duration.displayValue}`
                                                    : "Geen voorkeur"}

                                                <img src={selectArrowImage} alt=""/>
                                                {mReisduurDropdown && (
                                                    <div
                                                        className="select-content"
                                                        ref={selectMReisduurRef}
                                                    >
                                                        <h3 className="m-0 p-0 d-flex align-items-start">Reisduur</h3>
                                                        <div
                                                            className="item"
                                                            onClick={() => {
                                                                handleDuration(null);
                                                                setDReisduurDropdown(false);
                                                            }}>Geen voorkeur
                                                        </div>
                                                        <hr/>
                                                        {["7 Dagen", "14 Dagen", "21 Dagen"].map((it, i) => (
                                                            <div
                                                                key={i}
                                                                className="item"
                                                                onClick={() => {
                                                                    handleDuration(it)
                                                                    setMReisduurDropdown(false);
                                                                }}
                                                            >
                                                                {DurationUtil.renderDurationDisplayValue(it)}
                                                                {duration?.displayValue === it && (
                                                                    <RightMark/>
                                                                )}
                                                            </div>
                                                        ))}

                                                        <hr/>

                                                        {[
                                                            "1-3 Dagen",
                                                            "3-5 Dagen",
                                                            "4-6 Dagen",
                                                            "6-8 Dagen",
                                                            "7-9 Dagen",
                                                            "10-12 Dagen",
                                                            "13-15 Dagen",
                                                            "16-18 Dagen",
                                                            "19-21 Dagen",
                                                            "22-24 Dagen",
                                                            "25-27 Dagen",
                                                            "28-30 Dagen",
                                                        ].map((it, i) => (
                                                            <div
                                                                key={i}
                                                                className="item"
                                                                onClick={() => {
                                                                    handleDuration(it);
                                                                    setMReisduurDropdown(false);
                                                                }}
                                                            >
                                                                {it}
                                                                {duration?.displayValue === it && (
                                                                    <RightMark/>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </button>
                                        </div>
                                        <div/>
                                        <div/>
                                    </div>
                                    <h5 className="mt-md-5 mt-3">Maand</h5>

                                    <div className="maad select-btn-wrapper">
                                        { renderMonths(filters) }
                                    </div>

                                    <div className="bottom-btn-group">
                                        {
                                            selectedFilters.findIndex(f => f.filterType === "departure" || f.filterType === "duration") > -1 &&
                                            <button
                                                className="link-btn"
                                                onClick={() => {
                                                    const newFilters = selectedFilters.filter(f => f.filterType !== "departure" && f.filterType !== "duration");
                                                    setSearchParams(params => {
                                                        params.set("filter", UrlUtil.parseFiltersToUrlParams(FilterUtil.removeDuplicates(newFilters)));
                                                        return params;
                                                    });
                                                    dispatch(setActiveFilters([...newFilters]));
                                                }}>Verwijder keuze</button>
                                        }
                                        <button
                                            className="opslaan-btn"
                                            onClick={() =>
                                                setIsopenEnterDateDropdown(!isopenEnterDateDropdown)
                                            }
                                        >
                                            Opslaan
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EnterDate;
