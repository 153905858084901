import {Action} from "redux";
import {
    ADD_PARTICIPANT_ADULT, ADD_PARTICIPANT_BABY,
    ADD_PARTICIPANT_CHILD,
    REMOVE_PARTICIPANT_ADULT, REMOVE_PARTICIPANT_BABY,
    REMOVE_PARTICIPANT_CHILD
} from "../actions";

export interface ParticipantReducerState {
    participantAmountAdults: number;
    participantAmountChildren: number;
    participantAmountBabies: number;
}

const initialState: ParticipantReducerState = {
    participantAmountAdults: 2,
    participantAmountChildren: 0,
    participantAmountBabies: 0
}

const ParticipantReducer = (state: ParticipantReducerState = initialState, action: Action): ParticipantReducerState => {
    switch (action.type) {
        case ADD_PARTICIPANT_ADULT:
            return {
                ...state,
                participantAmountAdults: state.participantAmountAdults + 1
            };
        case REMOVE_PARTICIPANT_ADULT:
            return {
                ...state,
                participantAmountAdults: state.participantAmountAdults -1
            };
        case ADD_PARTICIPANT_CHILD:
            return {
                ...state,
                participantAmountChildren: state.participantAmountChildren + 1
            };
        case REMOVE_PARTICIPANT_CHILD:
            return {
                ...state,
                participantAmountChildren: state.participantAmountChildren -1
            };
        case ADD_PARTICIPANT_BABY:
            return {
                ...state,
                participantAmountBabies: state.participantAmountBabies + 1
            };
        case REMOVE_PARTICIPANT_BABY:
            return {
                ...state,
                participantAmountBabies: state.participantAmountBabies -1
            };
        default:
            return state;
    }
}

export default ParticipantReducer;