import React, { useEffect, useRef, useState } from "react";
import "./AddGuest.scss";
import {MinusIcon, PLusIcon} from "../../../Icons";
import {useDispatch, useSelector} from "react-redux";
import {ReducerStateTypes} from "../../../types/redux/ReducerStateTypes";
import {
  addParticipantAdult, addParticipantBaby,
  addParticipantChild,
  removeParticipantAdult, removeParticipantBaby,
  removeParticipantChild,
} from "../../../actions";
import AgeGroup from "./AgeGroup";

const AddGuest: React.FC = () => {
  const [isOpenAddGuestDropDown, setIsopenAddGuestDropdown] = useState(false);
  const [ageDropdown, setAgeDropdown] = useState(false);
  const participantAmountAdults: number = useSelector((state: ReducerStateTypes) => state.participant.participantAmountAdults);
  const participantAmountChildren: number = useSelector((state: ReducerStateTypes) => state.participant.participantAmountChildren);
  const participantAmountBabies: number = useSelector((state: ReducerStateTypes) => state.participant.participantAmountBabies);
  const dispatch = useDispatch();
  //handle outside click
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const onOutsideClick = () => {
    setIsopenAddGuestDropdown(false);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onOutsideClick();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onOutsideClick]);

  const selectAgeRef = useRef<HTMLDivElement | null>(null);
  const onOutClickAge = () => {
    setAgeDropdown(false);
    setAgeDropdown(false);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectAgeRef.current &&
        !selectAgeRef.current.contains(event.target as Node)
      ) {
        onOutClickAge();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onOutClickAge]);

  const renderDisplayValue = () => {
    if (participantAmountAdults || participantAmountChildren || participantAmountBabies) {
      if (participantAmountAdults > 0 && participantAmountChildren + participantAmountBabies > 0) {
        return participantAmountAdults + " volw - " + (participantAmountChildren + participantAmountBabies) + " kind.";
      }
      if (participantAmountAdults > 0) {
        return participantAmountAdults + " volw.";
      }
      if (participantAmountChildren + participantAmountBabies > 0) {
          return (participantAmountChildren + participantAmountBabies) + " kind.";
      }
    }
    return "Selecteer Reisgezelschap";
  }

  return (
    <div>
      {" "}
      <div className=" add-guest-dropdown" ref={wrapperRef}>
        <h5 onClick={() => setIsopenAddGuestDropdown(!isOpenAddGuestDropDown)}>
          {renderDisplayValue()}
        </h5>

        {isOpenAddGuestDropDown && (
          <div className="guest-add-dropdown-contents">
            <div className="select-contents">
              <h4>Uw reisgezelschap</h4>
              <h6 className="mt-2">
                Het reisgezelschap bestaat uit volwassenen, kinderen en baby's, met een maximaal van 8 personen.
              </h6>

              <div className="companion-age-wrapper">
                <AgeGroup
                    ageGroupName="Volwassenen"
                    ageGroupSubTitle="18+"
                    addParticipant={addParticipantAdult}
                    removeParticipant={removeParticipantAdult}
                    participantAmount={participantAmountAdults}
                    addDisabled={(participantAmountAdults + participantAmountChildren + participantAmountBabies)=== 8}
                    removeDisabled={(participantAmountAdults + participantAmountChildren + participantAmountBabies)=== 1 || participantAmountAdults === 1} />
                <AgeGroup
                    ageGroupName="Kinderen"
                    ageGroupSubTitle="2 t/m 17 jaar"
                    addParticipant={addParticipantChild}
                    removeParticipant={removeParticipantChild}
                    participantAmount={participantAmountChildren}
                    addDisabled={(participantAmountAdults + participantAmountChildren + participantAmountBabies)=== 8}
                    removeDisabled={(participantAmountAdults + participantAmountChildren + participantAmountBabies) === 1 || participantAmountChildren === 0} />
                <AgeGroup
                    ageGroupName="Baby's"
                    ageGroupSubTitle="0 t/m 1 jaar"
                    addParticipant={addParticipantBaby}
                    removeParticipant={removeParticipantBaby}
                    participantAmount={participantAmountBabies}
                    addDisabled={(participantAmountAdults + participantAmountChildren + participantAmountBabies)=== 8}
                    removeDisabled={(participantAmountAdults + participantAmountChildren + participantAmountBabies)=== 1 || participantAmountBabies === 0} />
              </div>

                <button
                    className="save-btn"
                    onClick={() => setIsopenAddGuestDropdown(false)}
                >
                  Opslaan
                </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddGuest;
