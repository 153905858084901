import React from "react";
import "./SearchBar.scss";
import {useSearchParams} from "react-router-dom";
import AddGuest from "../../common/AddGuest/AddGuest";
import SelectLocation from "../../common/SelectLocation/SelectLocation";
import EnterDate from "../../common/EnterDate/EnterDate";
import SelectDestination from "../../common/SelectDestination/SelectDestination";
import {useSelector} from "react-redux";
import {ReducerStateTypes} from "../../../types/redux/ReducerStateTypes";
import searchIcon from "../../../assets/img/search-normal.svg";
import {FilterReducerState} from "../../../reducers/FilterReducer";

const HomeSearchBar: React.FC = () => {

  const filterData: FilterReducerState = useSelector((state: ReducerStateTypes) => state.filter);
  const {countryFilters, cityFilters, regionFilters, vacationTypeFilters, availableFilters} = filterData;
  const [searchParams] = useSearchParams();

  return (
    <div id="search-bar" data-testid="home-search-bar">
      <div className="select-items-wrapper">
        <div className="select-item ">
          <h6>Vakantietype</h6>
          <SelectLocation
            showing="Selecteer vakantie type"
            vacationTypes={vacationTypeFilters}
          />
        </div>
        <div className="border-right" />
        <div className="select-item t">
          <h6>Bestemming</h6>
          <SelectDestination
            showing="Selecteer bestemming"
            countries={countryFilters}
            cities={cityFilters}
            regions={regionFilters}
          />
        </div>
        <div className="border-right" />
        <div className="select-item ">
          <h6>Wanneer</h6>
          <EnterDate filters={availableFilters} />
        </div>
        <div className="border-right" />
        <div className="select-item ">
          <h6>Wie</h6>
          <AddGuest />
        </div>
      </div>
        <a href={`/search?${searchParams.toString()}`} className="search-btn">
          <img src={searchIcon} alt=""/> Zoeken
        </a>
    </div>
  );
};

export default HomeSearchBar;
