import React from "react";
import {addParticipantChild, removeParticipantChild} from "../../../actions";
import {MinusIcon, PLusIcon} from "../../../Icons";
import {ReducerStateTypes} from "../../../types/redux/ReducerStateTypes";
import {useDispatch, useSelector} from "react-redux";
import {Action} from "redux";

interface AgeGroupProps {
    ageGroupName: string;
    ageGroupSubTitle: string;
    addParticipant: () => Action;
    removeParticipant: () => Action;
    participantAmount: number;
    addDisabled: boolean;
    removeDisabled: boolean;
}

const AgeGroup: React.FC<AgeGroupProps> = ({
                                               ageGroupName,
                                               ageGroupSubTitle,
                                               addParticipant,
                                               removeParticipant,
                                               participantAmount,
                                               addDisabled,
                                               removeDisabled
                                           }) => {

    const dispatch = useDispatch();

    return (
        <div className="companion-age">
            <div>
                <h5>{ageGroupName}</h5>
                <h6>{ageGroupSubTitle}</h6>
            </div>

            <div className="input-number-box">
                <button
                    data-testid="remove-participant"
                    disabled={removeDisabled}
                    onClick={() => {
                        dispatch(removeParticipant())
                    }}>
                    <MinusIcon/>
                </button>

                <span>{participantAmount}</span>
                <button
                    data-testid="add-participant"
                    disabled={addDisabled}
                    onClick={() =>
                        dispatch(addParticipant())
                    }
                >
                    <PLusIcon/>
                </button>
            </div>
        </div>
    )
}

export default AgeGroup;