import React from "react";
import "./Hero.scss";
import Header from "../../global/header/Header";
import companyImage1 from "../../../assets/img/company/img1.svg";
import companyImage2 from "../../../assets/img/company/img2.svg";
import companyImage3 from "../../../assets/img/company/img3.svg";
import companyImage4 from "../../../assets/img/company/img4.svg";
import companyImage5 from "../../../assets/img/company/img5.svg";
import SearchBar from "../../common/SearchBar/SearchBar";

const Hero: React.FC = () => {
  return (
    <header id="hero">
      <div className="container">
        <div className=" head-contents">
          <Header />
        </div>
        <button className="outline-btn">We've Got Your Wings Covered</button>
        <h1>Your Dream Trip Starts here, Compare & Book your Flight</h1>
        <SearchBar />
        <div>
          <div className="company-img-group">
            <img className="img1" src={companyImage1} alt="Company 1" />
            <img className="img2" src={companyImage2} alt="Company 2" />
            <img className="img3" src={companyImage3} alt="Company 3" />
            <img className="img4" src={companyImage4} alt="Company 4" />
            <img className="img5" src={companyImage5} alt="Company 5" />
          </div>

          <p>We are comparing these company's</p>
        </div>
      </div>
    </header>
  );
};

export default Hero;
