import React, {useState} from "react";
import { CloseSideBarArrow } from "../../../Icons";
import FilterBar from "../Filter/Filter";
import Pagination from "./Pagination";
import "./TravelPackage.scss";
import {Vacation} from "../../../types/types";
import SearchResults from "../SearchResults/SearchResults";
import {useSearchParams} from "react-router-dom";

interface TravelPackageProps {
  vacations?: Vacation[];
  vacationCount: number;
}

const TravelPackage: React.FC<TravelPackageProps> = ({
  vacations,
  vacationCount,
}) => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  const [searchParams] = useSearchParams();

  return (
    <section data-testid={"vacation-deals-section"} id="vacation-deals">
      <div className="container">
        {!show ? (
          <button
            type="button"
            onClick={() => setShow(true)}
            style={{ transform: "rotate(180deg)" }}
            className="border-0 outline-0 bg-transparent mb-4 d-none d-lg-block"
          >
            <CloseSideBarArrow />
          </button>
        ) : (
          ""
        )}
        <div className={`vacation-contents ${show ? "" : "active"}`}>
          <div className="vac-sidebar d-none d-lg-block">
            <div className="inner-div">
              <FilterBar handleClose={handleClose} />
            </div>
          </div>
          <SearchResults
              vacationCount={vacationCount}
              vacations={vacations}
          />
        </div>

        <Pagination
            vacationCount={vacationCount}
            pageSize={25}
            currentPage={parseInt(searchParams.get("page"))} />
      </div>
    </section>
  );
};

export default TravelPackage;
