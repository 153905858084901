import React, {useRef} from "react";
import SelectCity from "./SelectCity/SelectCity";
import {CityFilterValue, FilterSearch, FilterValue} from "../../../../types/types";
import "./SelectRegion.scss";

interface SelectRegionProps {
    show: boolean;
    country: FilterSearch | undefined;
    setRegion: (value: string | undefined) => void;
    setCity: (value: string | undefined) => void;
    regions: Array<FilterValue>;
    cities: Array<CityFilterValue>;

}

const SelectRegion: React.FC<SelectRegionProps> = ({
    show,
    country,
    setRegion,
    setCity,
    regions,
    cities
}) => {

    const scrollableDivRef = useRef<HTMLDivElement>(null);

    if (!show) {
        return null;
    }

    if (country.value === "allCountries") {
        return (
            <div className="region-wrapper">
                <h5>{country.displayValue} ({country.count})</h5>
                <hr />
                <b><i>Selecteer een land om op plaats te filteren</i></b>
            </div>
        )
    }

    return (
        <div ref={scrollableDivRef} className="region-wrapper">
            <h5>{country.displayValue} ({country.count})</h5>
            <div className="select-btn-wrapper">
                {
                    regions?.map((region, index) => {
                        return <SelectCity
                            key={index}
                            region={region}
                            setCity={setCity}
                            setRegion={setRegion}
                            cities={cities}
                            scrollableDivRef={scrollableDivRef}
                        />
                    })
                }
            </div>
        </div>

    );
}

export default SelectRegion;